@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Layout styles */
.min-h-screen {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.custom-main-height {
  height: calc(100vh - 5rem); /* Adjust based on your header height */
  min-height: 0; /* Add this to prevent overflow */
}

/* Sidebar styles */
.sidebar-container {
  height: calc(100vh - 8rem); /* Adjust based on your header + padding */
  display: flex;
  flex-direction: column;
}

.sidebar-scroll {
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Map styles */
#map {
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: calc(100vh - 5rem);
}

@media (max-width: 1024px) {
  .custom-main-height {
    height: auto;
  }
  
  #map {
    height: 50vh;
  }
}

/* Other existing styles ... */

/* Add after your existing styles */
.bg-custom-main {
  background-color: #fff5cc;
}

.bg-custom-card {
  background-color: #fcfbf7;
}

.bg-custom-nav {
  background-color: #ffde59;
}